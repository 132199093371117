import { useCallback } from "react";
import { analyticsTrackerApi } from "@/react/api/analyticsTrackerApi";
import { usePunditUserContext } from "@circle-react/contexts/punditUserContext";
import type { CurrentCommunity } from "@circle-react/types/CurrentCommunity";
import type { CurrentCommunityMember } from "@circle-react/types/CurrentCommunityMember";
import type { EventType, SpecificProperties } from "./types";

const buildCommonProperties = (
  community?: CurrentCommunity,
  member?: CurrentCommunityMember,
) => ({
  community: community
    ? {
        id: community.id,
        slug: community.slug,
        name: community.name,
        is_on_trial: community.is_on_trial,
        is_private: community.is_private,
        is_expired_trial: community.is_expired_trial,
        trial_ends_at: community.trial_ends_at,
        locale: community.locale,
        created_at: community.created_at,
        current_plan_interval: community.current_plan_interval,
        current_plan_name: community.current_plan_name,
        current_plan_tier: community.current_plan_tier,
        current_plan: community.current_plan,
        plan_status: community.plan_status,
        default_currency: community.default_currency,
      }
    : undefined,
  user: member
    ? {
        id: member.id,
        is_admin: member.is_admin,
        is_moderator: member.is_moderator,
      }
    : undefined,
  path: window.location?.pathname,
  user_agent: window.navigator?.userAgent,
});

const buildProperties = (
  specificProperties: SpecificProperties,
  community?: CurrentCommunity,
  member?: CurrentCommunityMember,
) => ({
  community_id: community?.id,
  specific: specificProperties,
  common: buildCommonProperties(community, member),
  context: {},
});

export const useEventsTracker = () => {
  const { currentCommunity: community, currentCommunityMember: member } =
    usePunditUserContext();

  const trackEvent = useCallback(
    (event: EventType, specificProperties: SpecificProperties) => {
      const properties = buildProperties(specificProperties, community, member);
      // Using setTimeout to avoid blocking the main thread.
      // and avoid blocking the page by awaiting for the API call.
      // Queue system will be implemented in the future.
      setTimeout(() => {
        void analyticsTrackerApi.trackEvent(event, properties);
      }, 0);
    },
    [community, member],
  );

  return {
    trackEvent,
  };
};
